import React, { Component } from 'react';
import { Form, Input, Button, Message, Select,Row, Col,DatePicker } from 'antd'
import { queryPolicyPartnerCompany } from 'api/policy';
import { dictQuery } from 'api/base';


const { RangePicker } = DatePicker;
const { Option } = Select;

class FactorCreateQueryComponent extends Component {

    constructor(){
        super();
        this.state={
            operator: JSON.parse(sessionStorage.getItem('user')).loginName,
            enterpriseid: JSON.parse(sessionStorage.getItem('user')).businessCode,
            loading : false,
            partnerDetailInfoList: [],
            category: [],
        }
    }

    handlerReset(){
        this.props.form.resetFields();
    }

    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                this.props.searchPost(params);
            }
        })
    }

    componentWillMount() {
        let param = {
            code: "POLICY_CATEGORY",
            enterpriseid: this.state.enterpriseid,
        };
        //查询保单险种
        dictQuery(param).then(res => {
            let { errorCode, data } = res.data;
            if (errorCode === "0000") {
                this.setState({
                    category: data.dictList,
                });
            }
        });
        //查询保单合作单位
        queryPolicyPartnerCompany(param).then(res => {
            let { errorCode,errorMessage, data } = res.data;
            if (errorCode === "0000") {
                this.setState({
                    partnerDetailInfoList: data.partnerDetailInfoList,
                })
            }
        });

    }

    render() {
        const formItemStyle = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
              },
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
              },
        }

        const { getFieldDecorator } = this.props.form;
        const categoryOptions = this.state.category.map(category => {
            return <Option key={category.key} value={category.key} >{category.value}</Option>
        })
        const partnerDetailInfoListOptions = this.state.partnerDetailInfoList.map(partnerDetailInfoList => {
            return <Option key={partnerDetailInfoList.partnerId} value={partnerDetailInfoList.partnerId} >{partnerDetailInfoList.partnerName}</Option>
        })
        return (
            <div>
                <Form { ...formItemStyle }  onSubmit={this.handlerSubmit.bind(this)} >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="创建开始日期" >
                                {getFieldDecorator('createBeginDate')(<DatePicker />)}
                            </Form.Item> 
                        </Col>
                        <Col span={8}>
                            <Form.Item label="创建结束日期" >
                                {getFieldDecorator('createEndDate')(<DatePicker />)}
                            </Form.Item> 
                        </Col>
                        <Col span={8}>
                            <Form.Item label="批次号" >
                                { getFieldDecorator("batchNo",{
                                })(
                                    <Input />
                                ) }
                            </Form.Item> 
                        </Col>
                        <Col span={8}>
                            <Form.Item label="出单起始日期" >
                                {getFieldDecorator('issueBeginDate')(<DatePicker />)}
                            </Form.Item> 
                        </Col>
                        <Col span={8}>
                            <Form.Item label="出单结束日期" >
                                {getFieldDecorator('issueEndDate')(<DatePicker />)}
                            </Form.Item> 
                        </Col>
                        
                        <Col span={8}>
                            <Form.Item label="险种" >
                                { getFieldDecorator("category",{
                                })(
                                    <Select style={{ width: "100%" }} placeholder="险种">
                                        {categoryOptions}
                                    </Select>
                                ) }
                            </Form.Item> 
                        </Col>

                        <Col span={8}>
                            <Form.Item label="合作单位" >
                                { getFieldDecorator("partnerCompany",{
                                })(
                                    <Select style={{ width: "100%" }} placeholder="合作单位">
                                        {partnerDetailInfoListOptions}
                                    </Select>
                                ) }
                            </Form.Item> 
                        </Col>
                        <Col span={16} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                            查询
                            </Button>
                            <Button style={{ marginLeft: 8 }} onClick={this.handlerReset.bind(this)}>
                            重置
                            </Button>

                        </Col>
                    </Row>
                </Form> 
            </div>
            
        );
    }
}

FactorCreateQueryComponent = Form.create()(FactorCreateQueryComponent)

export default FactorCreateQueryComponent;