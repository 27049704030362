import React, { Component } from 'react';
import QueryBatchList from '@/components/factorManger/factorQuery/QueryBatchList'
import { Modal} from 'antd'



class ShowBatchListDetail extends Component {
    constructor(){
        super();
        this.state={
            showBatchList: false,   //批次详情弹出框控制 
        }
    }

    //批次详情弹出窗口关闭
    showBatchListCancel = e => {
        this.setState({
            showBatchList: false
        })
    }
    //批次详情弹出窗口显示
    showBatchListModa () {
        this.setState({
            showBatchList: true,
        })
    }
    
    render() {
        return (
            <div>
                
                {this.props.factororder !=null ? 
                <span className="span-click" onClick={this.showBatchListModa.bind(this)}>{this.props.factororder}</span>
                : <span className="span-click" onClick={this.showBatchListModa.bind(this)}>{this.props.batchNo}</span>}

                <Modal
                    style={{ top: '5%' }}
                    onCancel={this.showBatchListCancel.bind(this)}
                    visible={this.state.showBatchList}
                    footer={null}
                    title={"批次列表"}
                    width={1200}
                >
                    <QueryBatchList queryType={this.props.queryType} batchNo={this.props.batchNo} factororder={this.props.factororder}/>
                </Modal> 
            </div>
        );
    }
}

export default ShowBatchListDetail;