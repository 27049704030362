import React, { Component } from 'react';
import { Table, Menu, Tooltip } from 'antd'
import PolicyResultDetails from '../../policy/PolicyResultDetails'
import numeral from 'numeral';
import { formatDate } from 'utils/Date'
import { queryBatchList, queryPolicyInfoList } from 'api/factorManager'
import { dictQuery } from 'api/base';
import { queryPolicyPartnerCompany } from 'api/policy';


function CustomExpandIcon(props) {
    let text;
    if (props.expanded) {
        text = '&#8679';
    } else {
        //   text = '&#8681; expand';
        text = '&#8681';
    }
    return (
        <Tooltip title="点击查看批次下保单">
            <a
                className="expand-row-icon"
                onClick={e => props.onExpand(props.record, e)}
                dangerouslySetInnerHTML={{ __html: text }}
                style={{ cursor: 'pointer' }}
            />
        </Tooltip>
    );
}


class QueryBatchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(sessionStorage.getItem("user")),
            batchListloading: false,
            batchInfo: [],
            policyInfoList: [],
            category : [],
            partnerDetailInfoList : [],
        }
    }

    componentWillMount() {
        let params = {
            enterpriseid: this.state.user.businessCode,
            batchNo: this.props.batchNo,
            factororder: this.props.factororder,
            queryType: this.props.queryType,
            code: "POLICY_CATEGORY",
        };
        queryBatchList(params).then(res => {
            let { errorCode, data } = res.data;
            if (errorCode === '0000') {
                this.setState({
                    batchInfo: data.policyBatchList,
                })
            }
        })
        //查询保单险种
        dictQuery(params).then(res => {
            let { errorCode, data } = res.data;
            if (errorCode === "0000") {
                this.setState({
                    category: data.dictList,
                });
            }
        });
        //查询保单合作单位
        queryPolicyPartnerCompany(params).then(res => {
            let { errorCode, data } = res.data;
            if (errorCode === "0000") {
            this.setState({
                partnerDetailInfoList: data.partnerDetailInfoList,
            })
            }
        });

    }

    

    render() {
        const _this = this;
        const columns = [
            {
                title: '批次号', width: 150, dataIndex: 'batchNo', key: 'batchNo', align: 'center',
            },
            {
                title: '出单日期', width: 150, dataIndex: 'issueDate', key: 'issueDate', align: 'center',
                render(val) {
                    return formatDate(new Date(val), 'yyyy-MM-dd');
                }
            },
            {
                title: '合作单位', width: 150, dataIndex: 'partnerCompanyName', key: 'partnerCompanyName', align: 'center',
            },
            {
                title: '险种', width: 150, dataIndex: 'categoryTxt', key: 'categoryTxt', align: 'center',
            },
            {
                title: '总笔数', width: 150, dataIndex: 'sumCnt', key: 'sumCnt', align: 'center',
            },
            {
                title: '公司佣金总额', width: 150, dataIndex: 'sumAmt', key: 'sumAmt', align: 'center',
                render(val) {
                    return numeral(val).format('0,0.00');
                }
            },

        ]

        const menu = (
            <Menu>
                <Menu.Item>Action 1</Menu.Item>
                <Menu.Item>Action 2</Menu.Item>
            </Menu>
        );
        const onExpandedRowsChange = record => {
            this.setState({
                batchListloading: true,
            });
            setTimeout(() => {
                this.setState({
                  batchListloading: false,
                });
            }, 100);
            // console.log(record);
            // let params = {
            //     batchNo: record[0],
            //     enterpriseid: this.state.user.businessCode,
            // }
            // this.setState({
            //     batchListloading: true,
            // });
            // queryPolicyInfoList(params).then(res => {
            //     let { errorCode, data } = res.data;
            //     if (errorCode === '0000') {
            //         this.setState({
            //             policyInfoList : data.ibsPolicyInfoList,
            //         });
            //     }
            //     this.setState({
            //         batchListloading: false,
            //     })
            // })
            
        }
        const expandedRowRender = record => {
            const columns = [
                {
                    title: '创建时间', dataIndex: 'createTime', key: 'createTime', align: 'center',
                    render(val) {
                        return formatDate(new Date(val), 'yyyy-MM-dd hh:mm:ss');
                    }
                },
                {
                    title: '出单日期',dataIndex: 'issueDate', key: 'issueDate', align: 'center',
                    render(val) {
                        return formatDate(new Date(val), 'yyyy-MM-dd');
                    }
                },
                { title: '批次号',dataIndex: 'batchNo', key: 'batchNo', align: 'center' },
                {
                    title: '保单号', dataIndex: 'policyNo', key: 'policyNo', align: 'center',
                    render(text, record, index) {
                        return <PolicyResultDetails 
                            record={record}  
                            category={_this.state.category} 
                            partnerDetailInfoList={_this.state.partnerDetailInfoList}/>
                    }
                },
                { title: '投保人', dataIndex: 'policyHolder', key: 'policyHolder', align: 'center' },
                { title: '被保险人', dataIndex: 'insuredPerson', key: 'insuredPerson', align: 'center' },
                {
                    title: '不含税保费', dataIndex: 'freeTax', key: 'freeTax', align: 'center',
                    render(val) {
                        return numeral(val).format('0,0.00');
                    }
                },
                { title: '险种', dataIndex: 'categoryTxt', key: 'categoryTxt', align: 'center' },
                {
                    title: '佣金比例', dataIndex: 'commRate', key: 'commRate', align: 'center',
                    render(val) {
                        return numeral(val).format('0,0.0000');
                    }
                },
                {
                    title: '公司佣金',dataIndex: 'companyComm', key: 'companyComm', align: 'center',
                    render(val) {
                        return numeral(val).format('0,0.00');
                    }
                },
                { title: '合作单位', dataIndex: 'partnerCompanyName', key: 'partnerCompanyName', align: 'center' },
                
            ];

            return <Table 
                        columns={columns} 
                        dataSource={record.policyInfoList} 
                        pagination={false} 
                        rowKey={record => record.policyId} 
                        loading={this.state.batchListloading} 
                        scroll={{ x: '130%' }}>
                    </Table>
        };
        return (
            <div>
                <Table
                    rowKey={record => record.batchNo}
                    loading={this.state.batchListloading}
                    size="middle "
                    dataSource={this.state.batchInfo}
                    expandedRowRender={expandedRowRender}
                    onExpandedRowsChange={onExpandedRowsChange}
                    columns={columns}
                    bordered
                    pagination={false}
                    expandIcon={CustomExpandIcon}
                    scroll={{ y: 500 }}
                >
                </Table>
            </div>
        );
    }
}

export default QueryBatchList;