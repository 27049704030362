import {http} from '../utils/http'

export function checkPolicyInput(data){
    return http.post("/policy/checkPolicyInput",data);
}

export function insertPolicy(data){
    return http.post("/policy/insertPolicy",data);
}

export function queryPolicyList(data){
    return http.post("/policy/queryPolicyList",data);
}

export function queryPolicyInformation(data){
    return http.post("/policy/queryPolicyInformation",data);
}

export function editPolicy(data){
    return http.post("/policy/editPolicy",data);
}

export function deletePolicy(data){
    return http.post("/policy/deletePolicy",data);
}

export function deletePolicyBatch(data){
    return http.post("/policy/deletePolicyBatch",data);
}

export function queryPolicyPartnerCompany(data){
    return http.post("/policy/queryPolicyPartnerCompany",data);
}

export function uploadPolicyBatch(data){
    return http.post("/policy/uploadPolicyBatch",data);
}

export function queryPolicyFileBatchList(data){
    return http.post("/policy/queryPolicyFileBatchList",data);
}

export function queryPolicyFileInfoList(data){
    return http.post("/policy/queryPolicyFileInfoList",data);
}