import React, { Component } from 'react';
import {Upload, Icon,Message} from 'antd'
import { baseHttpUrl } from '../../assets/settings';
import { getTokenStr } from '../../utils/http';
import { red } from '_ansi-colors@3.2.4@ansi-colors';


const { Dragger } = Upload;

class UploadFile extends Component {
    constructor(){
        super();
        this.state={
            user: JSON.parse(sessionStorage.getItem("user")),
            headersToken : getTokenStr(),
            fileList:[],
        }
    } 

    uploadChange = info => {
        this.setState({
            headersToken : getTokenStr(),
        });
        let fileList = [...info.fileList];
        // fileList = fileList.filter(file => {
        //     return (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") && file.size / 1024 / 1024 < 1;
        // });
        // if (fileList.length > 5) {
        //     message.error(`最多上传五张`);
        //     return false;
        // }
        //fileList = fileList.slice(-5);
 
        if (info.file.status !== "uploading") { }
 
        if (info.file.status === "done") {
            Message.success(`${info.file.name} 上传成功`);
            if (info.file.response.code === "0000") {
                fileList = fileList.filter(file => {
                    file = info.file;
                    file.id = info.file.response.fileId;
                    file.uid = info.file.response.fileId;
                    return true;
                });
            }
       }else if (info.file.status === "error") {
           if(info.file.response.message != ''){
                Message.error(`${info.file.name} ,${info.file.response.message}`);
           }else{
                Message.error(`${info.file.name} 上传失败`);
           }

            
       }
       this.setState({fileList});
       this.props.pfn(this.state.fileList);

    }

    uploadRemove = (file) => {
        this.setState(preState => ({
            fileList:[],
        })
        )
        this.props.deleteFile(file);
    }

    render() {
        const _this = this;
        const props = {
            name: 'file',
            multiple: true,
            accept: _this.props.acceptType,
            action: baseHttpUrl+_this.props.uploadActionUrl,
            data : {
                enterpriseid:_this.state.user.businessCode,
                operator : _this.state.user.username,
                uploadBatchId: _this.props.uploadBatchId,
                fileType: _this.props.fileType,
                acceptType : _this.props.acceptType,
            },
            headers :{
                Token : _this.state.headersToken,
            },
            onChange : _this.uploadChange,
            onRemove: _this.uploadRemove,
            fileList : this.props.defaultFileList,
        };
        return (
            <div>
                <Dragger {...props}> 
                    <Icon type="file-add" style={{ fontSize: '20px', color: '#08c', paddingRight:5 }} />{this.props.uploadName}
                    <p style={{fontSize:9,marginTop:5,color:'#8491FF'}}>支持类型:({_this.props.acceptType})</p>
                </Dragger>
            </div>
        );
    }
}

export default UploadFile;