import React, { Component } from 'react';
import { Modal, Button, Form, Input, DatePicker, Select, InputNumber, Tooltip, Message, Col,Row } from 'antd'
import ModalDrag from '@/components/Common/ModalDrag'
import DescriptionList from '@/components/Common/DescriptionList'
import { formatDate } from 'utils/Date'
import { dictQuery } from 'api/base';
import { queryPolicyPartnerCompany } from 'api/policy';
import moment from 'moment';
const { Option } = Select;

const { Description } = DescriptionList
class PolicyResultDetails extends Component {

    constructor() {
        super();
        this.state = {
            visiable: false,
            category: [],
            partnerDetailInfoList: [],

        }
    }

    handlerCancel() {
        this.setState({
            visiable: false
        })
    }

    handlerModalShow() {
        this.setState({
            visiable: true
        })
    }

    render() {
        const title = <ModalDrag title="保单详情" />;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 }
        };
        const categoryOptions = this.props.category.map(category => {
            return <Option key={category.key} value={category.key} >{category.value}</Option>
        });
        const partnerDetailInfoListOptions = this.props.partnerDetailInfoList.map(partnerDetailInfoList => {
            return <Option key={partnerDetailInfoList.partnerId + ''} value={partnerDetailInfoList.partnerId + ''} >{partnerDetailInfoList.partnerName}</Option>
        });
        return (
            <div>
                <span className="span-click" onClick={this.handlerModalShow.bind(this)}>{this.props.record.policyNo}</span>
                <Modal
                    style={{ top: '5%' }}
                    onCancel={this.handlerCancel.bind(this)}
                    visible={this.state.visiable}
                    footer={null}
                    title={title}
                    width={1200}
                >
                    <div style={{ wordBreak: 'break-word' }}>
                        <Row>

                            <Form {...formItemLayout}>
                                <Col span={12}>
                                    <Form.Item label="创建时间">
                                        {getFieldDecorator('createTime', {
                                            initialValue: formatDate(new Date(this.props.record.createTime), 'yyyy/MM/dd hh:mm:ss'),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入创建时间！',
                                                }
                                            ]
                                        })(<Input placeholder='创建时间' disabled />)}
                                    </Form.Item>
                                    <Form.Item label="出单日期">
                                        {getFieldDecorator('issueDate', {
                                            initialValue: moment(new Date(this.props.record.issueDate)),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入出单日期！',
                                                }
                                            ]
                                        })(<DatePicker placeholder='出单日期' style={{ width: '100%' }} disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="险种">
                                        {getFieldDecorator('category', {
                                            initialValue: this.props.record.category,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入险种！',
                                                }
                                            ]
                                        })(<Select style={{ width: '100%' }} placeholder="险种" disabled>
                                            {categoryOptions}
                                        </Select>)}
                                    </Form.Item>
                                    <Form.Item label="保单号">
                                        {getFieldDecorator('policyNo', {
                                            initialValue: this.props.record.policyNo,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入保单号！',
                                                }
                                            ]
                                        })(<Input placeholder='保单号' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="被保人姓名">
                                        {getFieldDecorator('insuredPerson', {
                                            initialValue: this.props.record.insuredPerson,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='被保人姓名' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="佣金比例">
                                        {getFieldDecorator('commRate', {
                                            initialValue: this.props.record.commRate,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入佣金比例！',
                                                }
                                            ]
                                        })(<InputNumber step={0.0001} min={0} max={1} style={{ width: '100%' }} placeholder='佣金比例' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="车牌号">
                                        {getFieldDecorator('carNumber', {
                                            initialValue: this.props.record.carNumber,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='车牌号' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="被保人身份证号">
                                        {getFieldDecorator('insuredCert', {
                                            initialValue: this.props.record.insuredCert,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='被保人身份证号' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="投保人证件号码">
                                        {getFieldDecorator('holderCert', {
                                            initialValue: this.props.record.holderCert,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='投保人证件号码' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="发动机号">
                                        {getFieldDecorator('engineNo', {
                                            initialValue: this.props.record.engineNo,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='发动机号' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="新车购置价">
                                        {getFieldDecorator('newCarPrice', {
                                            initialValue: this.props.record.newCarPrice,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<InputNumber step={0.01} min={0} style={{ width: '100%' }} placeholder='新车购置价' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="车型">
                                        {getFieldDecorator('carType', {
                                            initialValue: this.props.record.carType,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='车型' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="标保规则">
                                        {getFieldDecorator('standardGuaranteeRule', {
                                            initialValue: this.props.record.standardGuaranteeRule,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='标保规则' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="吨位">
                                        {getFieldDecorator('tonnage', {
                                            initialValue: this.props.record.tonnage,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='吨位' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="购置价范围">
                                        {getFieldDecorator('purchaseRange', {
                                            initialValue: this.props.record.purchaseRange,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='购置价范围' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="地区">
                                        {getFieldDecorator('area', {
                                            initialValue: this.props.record.area,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='地区' disabled/>)}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="批次号">
                                        {getFieldDecorator('batchNo', {
                                            initialValue: this.props.record.batchNo,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入批次号！',
                                                }
                                            ]
                                        })(<Input placeholder='批次号' disabled />)}
                                    </Form.Item>
                                    <Form.Item label="起保日期">
                                        {getFieldDecorator('startDate', {
                                            initialValue: this.props.record.startDate === null ? null : moment(new Date(this.props.record.startDate)),
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<DatePicker placeholder='起保日期' style={{ width: '100%' }} disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="合作单位">
                                        {getFieldDecorator('partnerCompany', {
                                            initialValue: { key: this.props.record.partnerCompany, label: this.props.record.partnerCompanyName },
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入合作单位！',
                                                }
                                            ]
                                        })(<Select labelInValue style={{ width: '100%' }} placeholder="合作单位" disabled>
                                            {partnerDetailInfoListOptions}
                                        </Select>)}
                                    </Form.Item>
                                    <Form.Item label="投保人姓名">
                                        {getFieldDecorator('policyHolder', {
                                            initialValue: this.props.record.policyHolder,
                                            rules: [
                                                {
                                                    required: false,
                                                    message: '请输入投保人姓名！',
                                                }
                                            ]
                                        })(<Input placeholder='投保人姓名' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="不含税保费">
                                        {getFieldDecorator('freeTax', {
                                            initialValue: this.props.record.freeTax,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入不含税保费！',
                                                }
                                            ]
                                        })(<InputNumber step={0.01} min={0} style={{ width: '100%' }} placeholder='不含税保费' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="公司佣金">
                                        {getFieldDecorator('companyComm', {
                                            initialValue: this.props.record.companyComm,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入公司佣金！',
                                                }
                                            ]
                                        })(<InputNumber step={0.01} min={0} style={{ width: '100%' }} placeholder='公司佣金' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="保费合计">
                                        {getFieldDecorator('priceTaxTotal', {
                                            initialValue: this.props.record.priceTaxTotal,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<InputNumber step={0.01} min={0} style={{ width: '100%' }} placeholder='保费合计' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="车架号/vin码">
                                        {getFieldDecorator('vin', {
                                            initialValue: this.props.record.vin,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='车架号/vin码' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="投保人手机号">
                                        {getFieldDecorator('holderMobile', {
                                            initialValue: this.props.record.holderMobile,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='投保人手机号' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="初登日期">
                                        {getFieldDecorator('firstLoginDate', {
                                            initialValue: this.props.record.firstLoginDate === null ? null : moment(new Date(this.props.record.firstLoginDate)),
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<DatePicker placeholder='初登日期' style={{ width: '100%' }} disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="车辆型号">
                                        {getFieldDecorator('carModel', {
                                            initialValue: this.props.record.carModel,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='车辆型号' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="险种属性">
                                        {getFieldDecorator('insuranceAttribute', {
                                            initialValue: this.props.record.insuranceAttribute,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='险种属性' disabled/>)}
                                    </Form.Item>

                                    <Form.Item label="使用性质">
                                        {getFieldDecorator('useCharacter', {
                                            initialValue: this.props.record.useCharacter,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='使用性质' disabled/>)}
                                    </Form.Item>

                                    <Form.Item label="座位数">
                                        {getFieldDecorator('seatNum', {
                                            initialValue: this.props.record.seatNum,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='座位数' disabled/>)}
                                    </Form.Item>
                                    <Form.Item label="车损">
                                        {getFieldDecorator('carDamage', {
                                            initialValue: this.props.record.carDamage,
                                            rules: [
                                                {
                                                    required: false,
                                                }
                                            ]
                                        })(<Input placeholder='车损' disabled/>)}
                                    </Form.Item>
                                </Col>
                            </Form>
                        </Row>
                        <Row>
                            <Col span={4} offset={10}>
                                <Button type="primary" onClick={this.handlerCancel.bind(this)} >关闭</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div >
        );
    }
}
PolicyResultDetails = Form.create()(PolicyResultDetails);
export default PolicyResultDetails;