import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Tooltip } from 'antd';
const SubMenu = Menu.SubMenu;


class SiderMenu extends Component {

    constructor(){
        super();
        this.state={
            openKeys : [],
            subOpenKeys : [],
        }
    }

    menuClick(e) {
        this.props.siderClick(e.key);
    }

    setOpenKeys(){
        this.setState({
            openKeys : [],
            subOpenKeys : [],
        })
    }

    subMenu(items){
        return items.map(item => {
            if(item.children){
                let title = <span><Icon type={item.icon} /><span>{item.name}</span></span>;
                if(!item.icon){
                    title = <span><span>{item.name}</span></span>;
                }else{
                    title = <span><Icon type={item.icon} /><span>{item.name}</span></span>;
                }
                return <SubMenu  key={item.path} title={ title }>
                            {this.subMenu(item.children)}
                        </SubMenu>;
            }else{
                let linkText = <span>{item.name}</span>;
                let content= '';
                let isTip = false;
                if( item.key=== 'M2019'){
                    content = <span>保险公司账单到期还款，企业授权的操作员可在线选择保理融资订单申请还款结算。需勾选保险公司还款流水提交至保理系统审核供保理商客户经理审核，保理商客户经理审核通过后，还款结算资金会划拨至企业融资提现户。</span>;
                    isTip = true;
                }
                if( item.key=== 'M2020'){
                    content = <span>保险公司账单到期还款，企业授权的操作员可在线勾选保险公司还款流水，无需保理订单即可申请。保理商客户经理审批通过后，资金清分资金会划拨至企业融资提现户。</span>;
                    isTip = true;
                }
                if(isTip){
                    linkText = (<span>{item.name} &nbsp;
                        <span style={{ position : 'fixed' }} >
                        <Tooltip placement="rightTop" overlayClassName='sider-tooltip' title={content}>
                        <Icon type="question-circle" theme="twoTone" />
                        </Tooltip>
                        </span>
                        </span>)

                }
                return  <Menu.Item key={item.path}>
                            <Link to={item.path}><span>{linkText}</span>
                            </Link>
                        </Menu.Item>
            }
            
        })
    }


    handlerOnOpenChange(openKeys){
        if(openKeys.length !== 0){
            const item = this.props.routesMenu.filter( menu => menu.path === openKeys[openKeys.length-1]);
            if( item.length !== 0 ){
                this.setState({
                    openKeys : [openKeys[openKeys.length-1]]
                })
            }else{
                const itemRoot = this.props.routesMenu.filter( menu => menu.path === openKeys[0]);
                if(itemRoot.length !== 0){
                    const subItem = itemRoot[0].children.filter( menu => menu.path === openKeys[openKeys.length-1]);
                    if(subItem !==0 ){
                        this.setState({
                            openKeys : [openKeys[0], openKeys[openKeys.length-1]]
                        })
                        
                    }else{
                        this.setState({
                            openKeys : openKeys
                        })
                    }
                }else{
                    this.setState({
                        openKeys : []
                    })
                }
            }
        }else{
            this.setState({
                openKeys : openKeys
            })
        }
    }

    render() {
        const menuHtml = this.props.routesMenu.map(menu => {
            if(menu.children){
                let title = <span><Icon type={menu.icon} /><span>{menu.name}</span></span>;
                return <SubMenu  key={menu.path} title={ title }>
                        {this.subMenu(menu.children)}
                    </SubMenu>;
            }else{
                return <Menu.Item key={menu.path}>
                    <Link to={menu.path}>
                        <Icon type={menu.icon} />
                    <span>{menu.name}</span></Link>
                </Menu.Item>
            }
        });

        return (
            <div className="sider-menu sider-menu-scroll">
                <div className="scrollbar">
                    <Menu theme="dark"
                    onClick={this.menuClick.bind(this)}
                    // openKeys={this.state.openKeys.concat(this.state.subOpenKeys)}
                    openKeys={this.state.openKeys}
                    selectedKeys = {[this.props.current] }
                    // defaultSelectedKeys={[this.props.current]}
                    mode="inline"
                    onOpenChange = {this.handlerOnOpenChange.bind(this)}
                    >
                    {menuHtml}
                    </Menu>
                </div>
            </div>
        );
    }
}

export default SiderMenu;