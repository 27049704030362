export function formatAmount(amount){
    amount = (amount || 0).toString();
    let result = '';
    while (amount.length > 3) {
        result = ',' + amount.slice(-3) + result;
        amount = amount.slice(0, amount.length - 3);
    }
    if (amount) { result = amount + result; }
    return result;
}

export function formatPercent(number){
    var m=0,s1= number ? number.toString() : '0';  
    try{m+=s1.split(".")[1].length}catch(e){}  
    return Number(s1.replace(".",""))*100 /Math.pow(10,m) + '%';
}