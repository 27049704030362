import {http} from '../utils/http'

export function queryFactoring(data){
    return http.post("/factor/queryFactoring",data);
}

export function searchBatchPolicyInfoSum(data){
    return http.post("/factor/searchBatchPolicyInfoSum",data);
}

export function applySubmit(data){
    return http.post("/factor/applySubmit",data);
}

export function queryFactorList(data){
    return http.post("/factor/query",data);
}

export function queryFactoringDetail(data){
    return http.post("/factor/queryFactoringDetail",data);
}
//查询保理编辑信息
export function getEditFactorInfo(data){
    return http.post("/factor/getEditFactorInfo",data);
}

//根据批次查询批次信息列表
export function queryBatchList(data){
    return http.post("/factor/queryBatchList",data);
}

//根据批次号查询保单信息
export function queryPolicyInfoList(data){
    return http.post("/factor/queryPolicyInfoList",data);
}

//首页图标数据展示
export function queryFacOrderCount(data){
    return http.post("/factor/queryFacOrderCount",data);
}
//首页数据展示
export function queryIndexInfo(data){
    return http.post("/factor/queryIndexInfo",data);
}

//上传文件
export function uploadAttachedFile(data){
    return http.post("/factor/uploadAttachedFile",data);
}