export const baseHttpUrl = "https://bd.51xinhai.com/ibsserver/";

export const httpTimeOut = 60000;

export const fastDsfUrl = "https://ffds.51xinhai.com/";

export const kkFileUrl = "https://ffds.51xinhai.com/kkfile/"

export const kkFileKey = "XHapp803&!@7899";

export const publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA16SyQZn/uMkWzpJNo7vHJYUoswA02/ej2EtTrwJ8WtDvPal8qLPFCeGcD7fATdJxLlBAI2I8TNE+kRbWx0I0phdk9Sh4OoFcixapEANu2pyrJZI3rw5F6PlK2immK8SnlMpKmz02e18bTvEMe+TJgCgAnMrflgBSFj42+4hn9jxfJnVGds6wY4km/gbX8lh5TbZ/qEomaUrh1XOXbU5O+3jgwH97EShYjhmodGHHR/5YC6xauNZ2L8CSYKby4J4VphEkICQF6jIqTWJEoTzV9V5dmqX6CdFnxvsBqxTlE/67+3W0Eud3e8kKLMPMBW2/Bl+FenopLM1j93J0AyItxQIDAQAB`;
