import React, { Component } from 'react';
import { Form, Input,InputNumber,Divider,Table, Button,Upload, Message, Modal,Row, Col,DatePicker,Card  } from 'antd'
import DescriptionList from 'coms/Common/DescriptionList'
import { formatPercent } from 'utils/Number'
import { formatDate } from 'utils/Date'
import { searchBatchPolicyInfoSum } from 'api/factorManager'
import { applySubmit } from 'api/factorManager'
import { queryFactoring } from 'api/factorManager'
import { getEditFactorInfo } from 'api/factorManager'
import FactorCreateQueryComponent from '@/components/factorManger/FactorCreateQueryComponent'
import ShowBatchListDetail from '@/components/factorManger/factorQuery/ShowBatchListDetail'
import './factor.less'
import UploadFile from '@/components/factorManger/UploadFile'
import uuid from 'uuid';


import numeral from 'numeral';
import moment from 'moment';

const { Description } = DescriptionList

const { TextArea } = Input;

function disabledDate(current) {
    // Can not select days before today
    return current && current < moment().subtract(1, 'days');
}
 
const columns = [
    {
      title: '批次号', width: 160, dataIndex: 'batchNo',  key: 'batchNo', align: 'center',fixed: 'left',
        render(text, record, index){
            return <ShowBatchListDetail batchNo={record.batchNo} />
        }
    },
    {
        title: '出单日期', width: 160,  dataIndex: 'issueDate', key: 'issueDate', align: 'center',fixed: 'left',
        render(val) {
            return formatDate(new Date(val), 'yyyy-MM-dd');
        }
    },
    {
        title: '合作单位', width: 210, dataIndex: 'partnerCompanyName', key: 'partnerCompanyName', align: 'center',fixed: 'left',fixed: 'left',
    },
    {
        title: '险种',  width: 170,  dataIndex: 'categoryTxt', key: 'categoryTxt', align: 'center',fixed: 'left',
    },
    {
        title: '账期', width: 160,  dataIndex: 'billDateTxt', key: 'billDateTxt', align: 'center',
		render(val){
			return <pre>{val}</pre>;
		}
    },
    {
        title: '最晚还款日', width: 160, dataIndex: 'payBackDateTxt', key: 'payBackDateTxt', align: 'center',
    },
    {
      title: '总笔数', width: 160, dataIndex: 'sumCnt', key: 'sumCnt',  align: 'center',
        render(val) {
        return numeral(val).format('0,0');
        }
    },
    {
      title: '公司佣金总额', width: 180, dataIndex: 'sumAmt', key: 'sumAmt',  align: 'center',
        render(val) {
        return numeral(val).format('0,0.00');
        }
    },

    {
        title: '保理申请状态', width: 180, dataIndex: 'fstatus', key: 'fstatus',  align: 'center',
        render(val) {
            if(val=="1"){
                return "未申请";
            }else if(val=="2"){
                return "失败";
            }else if(val=="0"){
                return "成功";
            }else{
                return "申请中";
            }
        }
      },
  ]; 

  

class factorEdit extends Component {
    constructor(){
        super();
        this.state={
            loading : false,
            factorData :{},
            searchParam : {},
            resultData : [],
            sumAmt:0,
            sumCnt:0,
            batchNo:[],
            visible: false,
            feeInfo : {},
            hasSelected: false,

            startValue: null,
            endValue: null,
            endOpen: false,
            selectArr : [],
            selectedRowKeys: [],

            fileList0: [],
            fileList1: [],
            fileList2: [],
            fileList3: [],
            uploadBatchId: uuid.v4(),

            rateCycle: "",
        }
    }

    componentWillMount(){
        let user = JSON.parse(sessionStorage.getItem('user'));
        let param ={ "enterpriseid" : user.businessCode,
                     "createUser" : user.username,
                    "factorOrder" : this.props.location.state.record.orderno}; 
        getEditFactorInfo(param).then(res => {
            let {errorCode, data } = res.data;
            if(errorCode === "0000" ){
                this.setState({
                    resultData : data.policyBatchList,
                    factorData : data.ibsFactoringOrder,
                    selectArr : data.policyBatchList,
                    sumAmt : data.ibsFactoringOrder.employ_amount,
                    sumCnt : data.ibsFactoringOrder.employ_num,
                    hasSelected : true,
                    batchNo : data.policyBatchList,
                    fileList0 : data.fileList0,
                    fileList1 : data.fileList1,
                    fileList2 : data.fileList2,
                    fileList3 : data.fileList3,
                    uploadBatchId : this.props.location.state.record.orderno,
                })
            }
        })
        
    }

    disabledStartDate = startValue => {
        const endValue = this.state.endValue;
        
        if (!startValue || !endValue) {
          return disabledDate(startValue);
        }
        return disabledDate(startValue) || startValue.valueOf() > endValue.valueOf();
      };
    
      disabledEndDate = endValue => {
        const startValue = this.state.startValue;
        if (!endValue || !startValue) {
          return disabledDate(endValue);
        }
        return disabledDate(endValue) || endValue.valueOf() <= startValue.valueOf();
      };

      onChangeFunction = (field, value) => {
        this.setState({
          [field]: value,
        });
      };

      onStartChange = value => {
        this.onChangeFunction('startValue', value);
      };
    
      onEndChange = value => {
        this.onChangeFunction('endValue', value);
      };
      handleStartOpenChange = open => {
        if (!open) {
          this.setState({ endOpen: true });
        }
      };
    
      handleEndOpenChange = open => {
        this.setState({ endOpen: open });
      };

    

    showModal = () => {
        this.props.form.validateFields((err, params) => {
            if(!err){
                let user = JSON.parse(sessionStorage.getItem('user'));
                params.enterpriseid = user.businessCode;
                params.createUser = user.username;
                params.batchNoArr = this.state.batchNo;
                let rateCycleTemp="未知";
                queryFactoring(params).then(res =>{
                    let {errorCode, errorMessage,data} = res.data;
                    if( errorCode === '0000' ){
                        if(data.ibsFactoringOrder.rateCycle==='0'){
                            rateCycleTemp="按年计算";
                        }else if(data.ibsFactoringOrder.rateCycle==='1'){
                            rateCycleTemp="按月计算";
                        }else if(data.ibsFactoringOrder.rateCycle==='2'){
                            rateCycleTemp="按日计算";
                        }
                        this.setState({
                            rateCycle: rateCycleTemp,
                            visible: true,
                            feeInfo : data.ibsFactoringOrder,
                          });
                    }else{
                        Message.error(errorMessage);
                    }
                })
            }  
        })
        
      };

    handleCancel = e => {
    this.setState({
        visible: false,
    });
    };

    handlerReset(){
        this.props.form.resetFields();
    }

    handerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                this.setState({
                    loading : true
                });
                let user = JSON.parse(sessionStorage.getItem('user'));
                params.enterpriseid = user.businessCode;
                params.createUser = user.username;
                params.batchNoArr = this.state.batchNo;
                params.orderno = this.state.factorData.orderno;
                params.fileList0 = this.state.fileList0;
                params.fileList1 = this.state.fileList1;
                params.fileList2 = this.state.fileList2;
                params.fileList3 = this.state.fileList3;
                applySubmit(params).then(res =>{
                    let {errorCode, errorMessage} = res.data;
                    if( errorCode === '0000' ){
                        Message.success('申请成功');
                        this.handlerReset();
                        this.props.history.push('/factorQuery');
                    }else{
                        Message.error(errorMessage);
                    }

                    this.setState({
                        loading : false
                    })
                })
            }  
        })
    }

    searchFormSubmit(params){
        this.setState({
            searchParam : params
        })
        this.searchPost(params);
    }

    searchPost(params){
        params.enterpriseid = JSON.parse(sessionStorage.getItem('user')).businessCode;
        params.factororder = this.props.location.state.record.orderno;
        searchBatchPolicyInfoSum(params).then(res => {
            let { errorCode, data } =res.data;
            if(errorCode === '0000'){
                this.setState({
                    resultData : data.policyBatchList   
                })
            }
        })
        .catch(error => {
            Message.error('error');
        });
    }

    
    onSelectChange = (selectedRowKeys,selectedRows) => {
        if(selectedRows.length!=0){
            let totalAmt = 0;
            let totalCnt = 0;
            for(let i=0;i<selectedRows.length;i++){
                totalAmt += Number(selectedRows[i].sumAmt);
                totalCnt += Number(selectedRows[i].sumCnt);
            }

            this.setState({
                sumAmt : totalAmt,
                sumCnt : totalCnt,
                batchNo : selectedRows,
                hasSelected : selectedRowKeys.length>0,
            })

        }else{
            this.setState({
                sumAmt : 0,
                sumCnt : 0,
                hasSelected : selectedRowKeys.length>0,
            })
        }
        this.setState({ selectedRowKeys });

      };

      deleteFile(file){
        console.log(file);
      }

      setFileList0(fileList) {
        this.setState({
            fileList0: fileList //把父组件中的fileList替换为子组件传递的值
        })

      }
      setFileList1(fileList) {
        this.setState({
            fileList1: fileList //把父组件中的fileList替换为子组件传递的值
        })
      }
      setFileList2(fileList) {
        this.setState({
            fileList2: fileList //把父组件中的fileList替换为子组件传递的值
        })
      }
      setFileList3(fileList) {
        this.setState({
            fileList3: fileList //把父组件中的fileList替换为子组件传递的值
        })
      }

    render() {
        const { loading, selectedRowKeys,startValue, endValue, endOpen } = this.state;
        let selectedArr = this.state.resultData.filter( item => this.state.selectArr.findIndex( record => record.batchNo === item.batchNo ) >= 0 ).map( item => item.batchNo );
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: record => ({
                defaultChecked: selectedArr.includes(record.batchNo)
                
            }),
          };
        const formItemStyle = {
            // labelCol: {
            //     xs: { span: 24 },
            //     sm: { span: 11 },
            //   },
            //   wrapperCol: {
            //     xs: { span: 24 },
            //     sm: { span: 16 },
            //   },
        };
        
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="ant-advanced-search-form">
                    <FactorCreateQueryComponent searchPost={this.searchFormSubmit.bind(this)} />
                </div>

                <Form { ...formItemStyle }  className="ant-advanced-search-form"  onSubmit={this.handerSubmit.bind(this)} >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="批次总笔数" >
                            { getFieldDecorator("sumCnt",{
                                rules : [
                                    { required: true, message: '请输入批次总笔数' }
                                ],
                                initialValue : this.state.sumCnt,
                            })(
                                <Input style={{ width: '70%' }} readOnly />
                            ) }
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="公司佣金总额" >
                            { getFieldDecorator("sumAmt",{
                                rules : [
                                    { required: true, message: '请输入公司佣金总额' }
                                ],
                                initialValue : this.state.sumAmt,
                            })(
                                <Input style={{ width: '80%' }} readOnly />
                            ) }
                        </Form.Item> 
                    </Col>
                    <Col span={8}>
                        <Form.Item label="保理申请金额" >
                            { getFieldDecorator("apply_amount",{
                                initialValue : this.state.factorData.apply_amount,
                                rules : [
                                    { required: true, message: '请输入保理申请金额' }
                                ]
                            })(
                                <InputNumber step={0.01} min={0} max={this.state.sumAmt} style={{width:160}} />
                            ) }
                        </Form.Item>
                    </Col>            
                </Row> 
                <Row gutter={24}>            
                        <Col span={8}>
                            <Form.Item label="保理申请开始日期" >
                                {getFieldDecorator('start_time',{
                                    initialValue : moment(this.state.factorData.start_time,'YYYY-MM-DD'),
                                    rules: [{ type: 'object', required: true, message: '请选择日期' }],
                                    })
                                (<DatePicker format="YYYY-MM-DD" 
                                value={startValue} 
                                disabledDate={this.disabledStartDate}
                                onChange={this.onStartChange}
                                onOpenChange={this.handleStartOpenChange}/>)}
                            </Form.Item> 
                        </Col>
                        <Col span={8}>
                            <Form.Item label="保理申请结束日期" >
                                {getFieldDecorator('end_time',{
                                    initialValue : moment(this.state.factorData.end_time,'YYYY-MM-DD'),
                                    rules: [{ type: 'object', required: true, message: '请选择日期' }],
                                    })
                                (<DatePicker format="YYYY-MM-DD" 
                                value={endValue} 
                                disabledDate={this.disabledEndDate} 
                                onChange={this.onEndChange}
                                open={endOpen}
                                onOpenChange={this.handleEndOpenChange} />)}
                            </Form.Item> 
                        </Col>
                        <Col span={8}>
                            <p></p>
                        </Col>
                </Row>
                <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="申请原因" >
                                { getFieldDecorator("reasons",{
                                     initialValue : this.state.factorData.reasons,
                                })(
                                    <TextArea rows={2}  />
                                ) }
                            </Form.Item> 
                        </Col>
                        <Col span={12} >
                            <p></p>
                        </Col> 
                </Row>
                <Row gutter={24}>   
                        <Col span={6}>
                            <UploadFile 
                                uploadName="点击或拖拽发票文件进行上传"
                                fileType="0" 
                                pfn={this.setFileList0.bind(this)}
                                deleteFile={this.deleteFile.bind(this)}
                                defaultFileList={this.state.fileList0} 
                                uploadActionUrl="/factor/uploadAttachedFile"
                                acceptType=".jpg,.png,.gif,.jpeg,.pdf"
                                uploadBatchId={this.state.uploadBatchId}
                            />
                        </Col> 
                        <Col span={6}>
                            <UploadFile 
                                uploadName="点击或拖拽合同文件进行上传"
                                fileType="1"
                                pfn={this.setFileList1.bind(this)}
                                deleteFile={this.deleteFile.bind(this)}
                                defaultFileList={this.state.fileList1}
                                uploadActionUrl="/factor/uploadAttachedFile"
                                acceptType=".zip,.rar,.7z"
                                uploadBatchId={this.state.uploadBatchId}
                            />
                        </Col>  
                        <Col span={6}>
                            <UploadFile 
                                uploadName="点击或拖拽对账单文件进行上传"
                                fileType="2"
                                pfn={this.setFileList2.bind(this)}
                                deleteFile={this.deleteFile.bind(this)}
                                defaultFileList={this.state.fileList2}
                                uploadActionUrl="/factor/uploadAttachedFile"
                                acceptType=".zip,.rar,.7z"
                                uploadBatchId={this.state.uploadBatchId}
                            />
                        </Col> 
                        <Col span={6}>
                            <UploadFile 
                                uploadName="点击或拖拽其他文件进行上传"
                                fileType="3"
                                pfn={this.setFileList3.bind(this)}
                                deleteFile={this.deleteFile.bind(this)}
                                defaultFileList={this.state.fileList3}
                                uploadActionUrl="/factor/uploadAttachedFile"
                                acceptType=".zip,.rar,.7z"
                                uploadBatchId={this.state.uploadBatchId}
                            />
                        </Col> 
                </Row>
                <Row gutter={24} type="flex" style={{marginTop:30}} align="bottom">
                        <Col span={8} >
                        <p></p>
                        </Col> 
                        <Col span={8} style={{ textAlign: 'right' }}>
                            <p></p>
                        </Col>
                        <Col span={8} style={{ textAlign: 'right'}}>
                            <Button type="primary" onClick={this.showModal}>
                            费用详情
                            </Button>
                            <Button style={{ marginLeft: 8 }} loading={loading} disabled={!this.state.hasSelected} type="primary" htmlType="submit">
                            提交
                            </Button>
                            <Button style={{ marginLeft: 8 }}  onClick={this.handlerReset.bind(this)}>
                                重置
                            </Button>
                        </Col>
                </Row>
                
            </Form>

            <Table 
                rowSelection={rowSelection} 
                rowKey={record => record.batchNo} 
                columns={columns} 
                dataSource={this.state.resultData} 
                scroll={{ x: 1600 }}
            /> 


            <Modal
                visible={this.state.visible}
                onCancel={this.handleCancel }
                footer={null}
                width={800}
                >
                
                <Card bordered={false} title="费用详情" >
                    <DescriptionList size="large" col="2" style={{ marginBottom: 32 }} >
                    <Description term="保理申请金额">{numeral(this.state.feeInfo.apply_amount).format('0,0.00')}</Description>
                    <Description term="公司佣金总额">{numeral(this.state.feeInfo.employ_amount).format('0,0.00')}</Description>
                    <Description term="总笔数">{this.state.feeInfo.employ_num}</Description>
                    <Description term="最高申请额度">{numeral(this.state.feeInfo.factoring_applylimit).format('0,0.00')}</Description>
                    <Description term="保理申请开始日期">{this.state.feeInfo.start_time}</Description>
                    <Description term="保理申请结束日期">{this.state.feeInfo.end_time}</Description>
                    <Description term="保理回款账户名">{this.state.feeInfo.return_accountname}</Description>
                    <Description term="保理回款账号">{this.state.feeInfo.return_accountno}</Description>
                    <Description term="保理回款账户开户行">{this.state.feeInfo.return_openbank==='0001'?'齐商银行':'苏宁银行'}</Description>
                    <Description term="保理手续费率">{formatPercent(this.state.feeInfo.factoring_servicerate)}</Description>
                    <Description term="保理手续费">{numeral(this.state.feeInfo.factoring_servicefee).format('0,0.00')}</Description>
                    <Description term="保理使用费率">{formatPercent(this.state.feeInfo.factoring_userate)}</Description>
                    <Description term="保理使用费">{numeral(this.state.feeInfo.factoring_usefee).format('0,0.00')}</Description>
                    <Description term="宽限期">{this.state.feeInfo.factoring_grace}</Description>
                    <Description term="宽限期费率">{formatPercent(this.state.feeInfo.factoring_gracerate)}</Description>
                    <Description term="逾期使用费率">{formatPercent(this.state.feeInfo.factoring_overduerate)}</Description>
                    <Description term="利率计算方式">{this.state.rateCycle}</Description>
                    </DescriptionList>
                    <Divider style={{ marginBottom: 10 }} />
                </Card>

            </Modal>
            

            </div>
            
        );
    }
}
  

factorEdit = Form.create()(factorEdit)

export default factorEdit;

