import React, { Component } from 'react';
import { Modal} from 'antd'
import PassModify from 'coms/UserInfo/PassModify'

class ForceChangePassword extends Component {
    render() {
        const title = <span>密码修改</span>;
        const formItemStyle = {
            labelCol : {  span: 6 },
            wrapperCol : { span: 10 }
        }
        return (
            <Modal
                visible={this.props.visible}
                title={title}
                closable={false}
                maskClosable={true}
                footer={null}
                width={500}
                >
                <PassModify formItemStyle={formItemStyle} {...this.props} />
            </Modal>
        );
    }
}

export default ForceChangePassword;